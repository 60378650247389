<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        <router-link to="/late-pay-step" class="back-button"
          ><i class="el-icon-back"></i
        ></router-link>
        <span v-if="$route.name === 'EditLatePayStep'"
          >Дараа төл засах
          <div class="editField">
            <el-row :gutter="12">
              <el-col :span="12" :offset="6">
                <div class="panel">
                  <el-form
                    v-loading="loading"
                    label-position="top"
                    size="mini"
                    :rules="rules"
                    :model="latePayStepCreate"
                    ref="latePayStepCreate"
                  >
                    <el-row :gutter="0">
                      <el-col :span="12" :offset="6">
                        <el-form-item label="Купоны дүн">
                          <el-input
                            type="number"
                            v-model="latePayStepDetail.loan_amount"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="Нийт захиалгын дүн">
                          <el-input
                            type="number"
                            v-model="latePayStepDetail.orders_amount"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="Одоо төлөх дүн">
                          <el-input
                            type="number"
                            v-model="latePayStepDetail.interest_amount"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="Захиалгын хоногийн лимит">
                          <el-input
                            type="number"
                            v-model="latePayStepDetail.last_order_date_limit"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="Бодох захиалгын тоо">
                          <el-input
                            type="number"
                            v-model="latePayStepDetail.min_order_count"
                          ></el-input>
                        </el-form-item>
                        <!-- <el-form-item prop="is_available">
                          <p>Ашиглаж болох эсэх?</p>
                          <el-switch
                            v-model="latePayStepDetail.is_available"
                            border
                            active-color="#13ce66"
                            :active-value="true"
                            :inactive-value="false"
                          ></el-switch>
                        </el-form-item> -->
                        <div style="margintop: 20px">
                          <el-row :gutter="20">
                            <el-col :span="12">
                              <router-link to="/late-pay-step">
                                <el-button size="medium" class="full-width"
                                  >Буцах</el-button
                                >
                              </router-link>
                            </el-col>
                            <el-col :span="12">
                              <a>
                                <el-button
                                  size="medium"
                                  type="success"
                                  @click="save"
                                  >Хадгалах</el-button
                                >
                              </a>
                            </el-col>
                          </el-row>
                        </div>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
              </el-col>
            </el-row>
          </div>
        </span>
        <span v-else-if="$route.name === 'AddLatePayStep'">
          Дараа төл нэмэх
          <div class="editField">
            <el-row :gutter="12">
              <el-col :span="12" :offset="6">
                <div class="panel">
                  <el-form
                    v-loading="createLoading"
                    label-position="top"
                    size="mini"
                    :rules="rules"
                    :model="latePayStepCreate"
                    ref="latePayStepCreate"
                  >
                    <el-row :gutter="0">
                      <el-col :span="12" :offset="6">
                        <el-form-item label="Купоны дүн" prop="loan_amount">
                          <el-input
                            type="number"
                            placeholder="0"
                            v-model="latePayStepCreate.loan_amount"
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          label="Нийт захиалгын дүн"
                          prop="orders_amount"
                        >
                          <el-input
                            type="number"
                            placeholder="0"
                            v-model="latePayStepCreate.orders_amount"
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          label="Одоо төлөх дүн"
                          prop="interest_amount"
                        >
                          <el-input
                            type="number"
                            placeholder="0"
                            v-model="latePayStepCreate.interest_amount"
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          label="Захиалгын хоногийн лимит"
                          prop="last_order_date_limit"
                        >
                          <el-input
                            type="number"
                            placeholder="0"
                            v-model="latePayStepCreate.last_order_date_limit"
                          ></el-input>
                        </el-form-item>
                        <el-form-item
                          label="Бодох захиалгын тоо"
                          prop="min_order_count"
                        >
                          <el-input
                            type="number"
                            placeholder="0"
                            v-model="latePayStepCreate.min_order_count"
                          ></el-input>
                        </el-form-item>
                        <el-form-item prop="is_available">
                          <p>Ашиглаж болох эсэх?</p>
                          <el-switch
                            v-model="latePayStepCreate.is_available"
                            border
                            active-color="#13ce66"
                            :active-value="true"
                            :inactive-value="false"
                          ></el-switch>
                        </el-form-item>
                        <el-row :gutter="20">
                          <el-col :span="12">
                            <router-link to="/late-pay-step">
                              <el-button size="medium" class="full-width"
                                >Буцах</el-button
                              >
                            </router-link>
                          </el-col>
                          <el-col :span="12">
                            <a>
                              <el-button
                                size="medium"
                                type="success"
                                @click="save"
                                >Хадгалах</el-button
                              >
                            </a>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
              </el-col>
            </el-row>
          </div>
        </span>
      </h3>
    </div>
  </div>
</template>
<script>
import service from "@/helpers/services.js";
import { getGroups } from "../utils/auth";
export default {
  created() {
    if (this.$route.name === "EditLatePayStep") {
      this.getLatePayStepById(this.$route.query.id);
    }
  },
  mounted() {
    getGroups().then(data => {
      this.role = data;
    });
  },
  data() {
    return {
      latePayStepDetail: {},
      loading: true,
      createLoading: false,
      latePayStepCreate: {
        loan_amount: "",
        interest_amount: "",
        last_order_date_limit: "",
        min_order_count: "",
        orders_amount: "",
        is_available: true
      },
      role: [],
      rules: {
        loan_amount: [
          {
            required: true,
            message: "Та купоний үнийн дүн оруулна уу",
            trigger: "blur"
          }
        ],
        interest_amount: [
          {
            required: true,
            message: "Та одоо төлөх дүн оруулна уу",
            trigger: "blur"
          }
        ],
        orders_amount: [
          {
            required: true,
            message: "Та нийт захиалгын дүн оруулна уу",
            trigger: "blur"
          }
        ],
        last_order_date_limit: [
          {
            required: true,
            message: "Та захиалгын хоногийн лимит оруулна уу",
            trigger: "blur"
          }
        ],
        min_order_count: [
          {
            required: true,
            message: "Та бодогдох захиалгын тоо оруулна уу",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    getLatePayStepById(id) {
      try {
        service
          .getLatePayStepById(id)
          .then(res => {
            this.loading = false;
            if (res?.data?.status === "success") {
              this.latePayStepDetail = res.data.data;
            } else {
              this.$notify.error({
                title: "Уучлаарай",
                position: "bottom-left",
                message: res.error.message,
                duration: 2000
              });
            }
          })
          .catch(error => {
            this.$notify.error({
              title: "Уучлаарай",
              position: "bottom-left",
              message: error.message,
              duration: 2000
            });
          });
      } catch (error) {
        this.$notify.error({
          title: "Уучлаарай",
          position: "bottom-left",
          message: error.message,
          duration: 2000
        });
      }
    },
    save() {
      this.$refs.latePayStepCreate.validate(valid => {
        if (valid) {
          if (this.$route.name === "EditLatePayStep") {
            service
              .updateLatePayStep(this.$route.query.id, this.latePayStepDetail)
              .then(response => {
                if (response.data.status === "success") {
                  this.$notify({
                    title: "Амжилттай",
                    message: "Алхам засагдлаа",
                    type: "success"
                  });
                  this.$router.go(-1);
                } else if (response.data.status === "unsuccess") {
                  this.$notify({
                    title: "Амжилтгүй",
                    message:
                      "Алхам засахад алдаа гарлаа " +
                      " " +
                      response.data.error.message,
                    type: "warning"
                  });
                }
              })
              .catch(error => {
                this.$notify.error({
                  title: "Уучлаарай",
                  position: "bottom-left",
                  message: error.message,
                  duration: 2000
                });
              });
          } else if (this.$route.name === "AddLatePayStep") {
            this.createLoading = true;
            service
              .createLatePayStep(this.latePayStepCreate)
              .then(response => {
                this.loading = false;
                if (response.data.status === "success") {
                  this.createLoading = false;
                  this.$notify({
                    title: "Амжилттай",
                    message: "Алхам нэмэгдлээ",
                    type: "success"
                  });
                  this.$router.go(-1);
                } else if (response.data.status === "unsuccess") {
                  this.createLoading = false;
                  this.$notify({
                    title: "Амжилтгүй",
                    message:
                      "Алхам нэмэхэд алдаа гарлаа" +
                      " " +
                      response.data.error.message,
                    type: "warning"
                  });
                }
              })
              .catch(error => {
                this.createLoading = false;
                this.$notify({
                  title: "Амжилтгүй",
                  message: "Алхам нэмэхэд алдаа гарлаа" + " " + error.message,
                  type: "warning"
                });
              });
          }
        } else {
          this.createLoading = false;
          this.$notify({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          return false;
        }
      });
    }
  }
};
</script>
<style>
.editField {
  margin-top: 25px;
}
p.wrapNormal {
  word-break: break-word;
  white-space: normal;
  text-align: justify;
}
</style>
